<template>
	<div class="content">
		<div class="title">
			<h1>Our Professional and Swift Service!</h1>
			<h4>
				With Lengex™, you can trade gift cards with the most competitive rates and instant response.
			</h4>
		</div>
		<div class="cdls" id="cdls" ref="target">
			<div class="card" :class="targetIsShowed ? 'animate__animated animate__slideInDown' : ''">
				<div class="icon">
					<span style="background-color: #FFEBDB;color: #FF5A0F;">
						<font-awesome-icon icon="fa-solid fa-rocket"></font-awesome-icon>
					</span>
				</div>
				<h3 class="label">Fast Loading</h3>
				<div class="discription">
					Safe transaction environment, fast payments. 1-on-1 professional service, ensuring the absolute safety of the card asset.
				</div>
			</div>
			<div class="card" :class="targetIsShowed ? 'animate__animated animate__slideInDown' : ''">
				<div class="icon">
					<span style="background-color: #E0F8F8;color: #00DDC1;">
						<font-awesome-icon icon="fa-solid fa-external-link-square-alt"></font-awesome-icon>
					</span>
				</div>
				<h3 class="label">High Rates</h3>
				<div class="discription">
					Lengex provide the best exchange rates to win more transactions for you from your customers.
				</div>
			</div>
			<div class="card" :class="targetIsShowed ? 'animate__animated animate__slideInDown' : ''">
				<div class="icon">
					<span style="background-color: #F7EDFF;color: #FF35B7;">
						<font-awesome-icon icon="fa-solid fa-money-check"></font-awesome-icon>
					</span>
				</div>
				<h3 class="label">Safe Payment</h3>
				<div class="discription">
					Honest is so important to us, integrity is our foundation. Usually we will complete each transaction within five minutes without delay.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRocket, faExternalLinkSquareAlt, faMoneyCheck} from '@fortawesome/free-solid-svg-icons'

library.add(faRocket, faExternalLinkSquareAlt, faMoneyCheck)
 
export default {
  name: 'sectionBox',
  components: {
    FontAwesomeIcon
  },
  data() {
	return{
		target: null,
		targetIsShowed: false
	}
  },
  mounted() {
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if(entry.isIntersecting && !this.targetIsShowed) {
				this.targetIsShowed = true
			}
		})
	})
	observer.observe(this.$refs.target)
  }
}
</script>

<style lang="less" scoped>
.content{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
	@media screen and (max-width: 767px){
		.title{
			width: 92%;
			flex-wrap: wrap;
			h1{
				font-size: 30px;
			}
			h4{
				font-size: 18px;
			}
		}
		.cdls{
			flex-direction: column;
			width: 92%;
			h3{
				font-size: 20px;
			}
			.discription{
				font-size:16px ;
			}
		}
	}
	@media screen and (min-width: 767px){
		.title{
			width: 88%;
		}
		.cdls{
			flex-direction: row;
			width: 88%;
			h3{
				font-size: 24px;
			}
			.discription{
				font-size:18px ;
			}
		}
	}
	@media screen and (min-width: 1000px){
		.title{
			width: 75%;
			h1{
				font-size: 48px;
			}
			h4{
				font-size: 20px;
				padding-top: 18px;
			}
		}
		.cdls{
			flex-direction: row;
			width: 75%;
			h3{
				font-size: 24px;
			}
			.discription{
				font-size:18px ;
			}
		}
	}
	.title{
		display: flex;
		text-wrap: wrap;
		text-align: left;
		justify-content: space-between;
		margin-bottom: 6%;
		>*{
			padding: 0 5%
		}
		h1{
			font-weight: 700;
		}
		h4{
			width: 50vw;
		}
	}
	.cdls{
		display: flex;
		.card{
			background-color: white;
			margin: 0.625rem 0.625rem;
			border-radius: 10px;
			padding: 1em;
			padding-bottom: 5em;
			.icon{
				margin: 25px 0;
				span{
					border-radius: 15px;
					padding:15px;
					font-size:25px;
				}
			}
			.label{
				font-weight: 600;
			}
			h3{
				margin: 0.8em 0 0.5em 0;
			}
			.discription{
				word-wrap: break-word;
			}
			transition: 0.5s;
		}
		.card:hover{
			box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
		}
	}
</style>