<template>
	<nav>
		<div class="branding">
			<a href="/public/index.html" ref="home">
				<img src="../assets/icon.png" alt="" />
			</a>
		</div>
		<div class="menu">
			<div class="menu-container">
				<ul>
					<li v-for="m in menuList">
						<a v-if="m.url" :href="m.url"><span @click="openAbout(true)"> {{ m.label }} </span></a>
						<a v-else><span @click="openAbout(false)"> {{ m.label }} </span></a>
					</li>
				</ul>
			</div>
			<div class="header_extra">
				<a-button shape="round"  @click="getChats">Contact WhatsApp</a-button>
			</div>
		</div>
		<div class="buttom">
			<div style="font-size: x-large;" @click="openMenu">
				<a-icon type="align-left" />
			</div>
		</div>
	</nav>
</template>

<script>
	import cj from '../assets/content.json'
	import { getChats } from '@/api/api';
	export default {
		name: 'MyHeader',
		data() {
			return {
				cj,
				menuList: [{
					label: 'Home',
					url: '#home'
				},{
					label: 'Why Us',
					url: '#whyus'
				},{
					label: 'FeedBack',
					url: '#feedback'
				},{
					label: 'FAQs',
					url: '#faqs'
				},{
					label: 'About Us',
					url: ''
				}]
			}
		},
		methods: {
			openMenu() {
				this.$emit('openMenu', true)
			},
			openAbout(status) {
				this.$emit('openAbout', status)
			},
			getChats() {
				getChats();
			}
		}
	}
</script>

<style lang="less" scoped>
	@media screen and (max-width: 980px){
		.menu {
			display: none;
		}
		.buttom {
			display: flex;
			cursor: pointer;
			i{
				height: 20px;
				width: 25px;
			}
		}
	}
	@media screen and (min-width: 981px){
		.menu {
			display: flex;
		}
		.buttom {
			display: none;
		}
	}
	nav{
		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
		padding: 35px;
		.branding {
			display: flex;
			justify-content: space-between;
			align-items: center;
			img{
				height: 2.5rem;
				width: 2.5rem;
			}
		}
		.menu{
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex: 1;
			padding: 0 20px;
			ul{
				list-style: none;
				padding: 0;
				margin: 0 0 0 50px;
				display: flex;
				font-size: 16px;
				li {
					margin-right: 14px;
					a{
						padding-right: 1.5625rem;
						padding-left: 1.5625rem;
					}
				}
			}
			.header_extra{
				button{
					background-color: #FF54B0;
					border-color: #FF54B0;
					color: white;
					height:5vh;
				}
				button:hover{
					background-color: blueviolet;
					border-color: blueviolet;
					transform: scale(1.1);
				}
			}
		}
	}
</style>