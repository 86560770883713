<template>
    <div class="about">
        <section>
            <h1>{{ cj['about-sec-h1-1'] }}</h1>
            <h4><p>{{ cj['about-sec-h4-1'] }} <a href="">{{ cj['about-sec-h4-2'] }}</a></p></h4>
            <h4><p>{{ cj['about-sec-h4-3'] }}</p></h4>
            <h4><p>{{ cj['about-sec-h4-4'] }}</p></h4>
        </section>
        <section>
            <h1>{{ cj['about-sec-h1-2'] }}</h1>
            <h4><p>{{ cj['about-sec-h4-5'] }}</p></h4>
        </section>
        <section>
            <h1>{{ cj['about-sec-h1-3'] }}</h1>
            <h4><p>{{ cj['about-sec-h4-6'] }}</p></h4>
            <h4><p>{{ cj['about-sec-h4-7'] }} <a href="">{{ cj['about-sec-h4-8'] }}</a></p></h4>
        </section>
    </div>
</template>

<script>
	import cj from '../assets/content.json'
    export default{
        data() {
            return{
                cj
            }
        }
    }
</script>

<style>
@media screen and (max-width: 767px){
    h1{
        font-weight: 700;
        font-size: 1.6rem;
    }
    h4{
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 767px){
    h1{
        font-weight: 700;
        font-size: 48px;
    }
    h4{
        font-size: 1.2rem;
    }
}
.about{
    margin: 0 80px 150px 80px;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    section{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
        text-align: left;
        margin-bottom: 5rem;
        a{
            color: #FF54B0;
        }
        a:hover{
            color: #6A45FF;
        }
    }
}
</style>