<template>
	<div class="content">
		<nav id="warp-nav">
			<div>
				<a href="">
					<img src="../assets/icon.png" alt="" />
				</a>
			</div>
			<a-icon 
				type="close"
				@click="closeWarp"
				style="font-size:24px;padding: 8px 14px 8px 0;margin-right: 5px;"
			/>
		</nav>
		<main>
			<ul>
				<li v-for="m in menuList">
					<a v-if="m.url" :href="m.url"><span @click="openAbout(true)"> {{ m.label }} </span></a>
					<a v-else><span @click="openAbout(false)"> {{ m.label }} </span></a>
				</li>
			</ul>
		</main>
		<footer>
			<a-button shape="round"  @click="getChats"> Contact WhatsApp </a-button>
		</footer>
	</div>
</template>

<script>
import { getChats } from '@/api/api';
	export default{
		name: 'Warp',
		data() {
			return {
				menuList: [{
					label: 'Home',
					url: '#home'
				},{
					label: 'Why Us',
					url: '#whyus'
				},{
					label: 'FeedBack',
					url: '#feedback'
				},{
					label: 'FAQs',
					url: '#faqs'
				},{
					label: 'About Us',
					url: ''
				}]
			}
		},
		methods: {
			closeWarp() {
				this.$emit('closeWarp', false)
			},
			openAbout(status) {
				this.$emit('closeWarp', false)
				this.$emit('openAbout', status)
			},
			getChats() {
				getChats();
			}
		}
	}
</script>

<style scoped>
	.content{
		z-index: 6;
		height: 100vh;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		background: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	nav{
		width: 100%;
		padding: 35px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		*{
			width: 2.5rem;
			height: 2.5rem;
		}
		div{
			display: flex;
		}
	}
	main ul{
		list-style: none;
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
		height: 50vh;
		justify-content: space-evenly;
		font-size: larger;
		color: #FF54B0;
	}
	footer{
		position: fixed;
		bottom: 30px;
		width: 100%;
		button{
			height: 6vh;
			width: 80%;
			max-width: 1020px;
			min-width: 160px;
			background-color: #FF54B0;
			border-color: #FF54B0;
			color: white;
		}
		.ant-btn:hover{
			background-color:#6A45FF;
			border-color:#6A45FF;
			transform: scale(1.1);
		}
	}
</style>