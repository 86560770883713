<template>
	<div class="item">
		<a-avatar :src="headSrc" size="default"/>
		<h4><slot name="name"></slot></h4>
		<h5><slot name="type"></slot></h5>
		<a-rate :default-value="stars" disabled />
		<p><slot name="commit"></slot></p>
	</div>
</template>

<script>
	export default{
		name: 'CardItem',
		props: ['stars', 'headSrc']
	}
</script>

<style lang="less">
@media screen and (min-width:820px) {
	.item{
		width: 28vw;
		margin-right: 3vw;
	}
}
@media screen and (max-width: 820px) {
	.item{
		width: 52vw;
		margin-right: 6vw;
	}
}
	.item{
		background-color: white;
		min-width: 220px;
		max-width: 360px;
		height: 95%;
		display: flex !important;
		flex-direction: column;
		justify-content: space-evenly;
		padding: 2.25rem;
		box-shadow: 0px 18px 27px 0px rgba(0, 0, 0, 0.05);
		margin-right: 1.875rem;
		border-radius: 15px;
		align-items: flex-start;
		h4{
			font-size: 18px;
		}
		h5{
			font-size: 15px;
			margin: 0;
			position: relative;
			top: -18px;
		}
		p{
			font-size: 18px;
			height: 42%;
			overflow: hidden;
			text-align: left;
		}
	}
</style>