<template>
	<div class="container">
		<div class="title-bar">
			<div>Over <span style="color: #6a45ff;">15000+ clients</span></div>
			<h1>Why you choose Lengex™?</h1>
			<h3>We have always provided professional and fast services and won the trust and praise of our customers.</h3>
		</div>
		<div class="content">
			<div class="left">
				<div class="up">
					<div class="icon" style="color: #00DDC1;">
						<!-- <img src="//32120810.s21i.faiusr.com/4/ABUIABAEGAAgvJubrwYov8zX1gcwogE4swE.png" alt="" /> -->
						<font-awesome-icon icon="fa-solid fa-line-chart"></font-awesome-icon>
					</div>
					<div class="label">Quick Response</div>
				</div>
				<div class="down">
					<div class="icon" style="color: #FF35B7;">
						<font-awesome-icon icon="fa-solid fa-user-edit"></font-awesome-icon>
					</div>
					<div class="label">Loyal to Customers</div>
				</div>
			</div>
			<div class="right">
				<div class="icon" style="color: #9C5EFF;">
					<font-awesome-icon icon="fa-solid fa-reply"></font-awesome-icon>
				</div>
				<div class="label">Continued Growth</div>
			</div>
		</div>
	</div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLineChart, faUserEdit, faReply} from '@fortawesome/free-solid-svg-icons'
 
library.add(faLineChart, faUserEdit, faReply)
 
export default {
  name: 'box1',
  components: {
    FontAwesomeIcon
  }
}
</script>

<style lang="less" scoped>
	@media screen and (max-width: 440px){
		.content{
			position: relative;
			left: -30%;
		}
	}
	@media screen and (max-width: 727px){
		.container{
			flex-direction: column;
			.title-bar{
				width: 90%;
				div{
					font-size: 18px
				}
				h1{
					font-size: 34px
				}
				h3{
					font-size: 16px
				}
			}
			.icon{
				font-size: 40px
			}
			.label{
				font-size: 20px
			}
			.up,.down,.right{
				padding: 1.575rem 1.975rem;
			}
		}
	}
	@media screen and (max-width: 1342px) and (min-width: 900px){
		.content{
			width: 50%;
		}
	}
	@media screen and (min-width: 900px){
		.title-bar{
			width: 50%;
		}
	}
	@media screen and (min-width: 727px){
		.container{
			flex-direction: row;
			.title-bar{
				div{
					font-size: 21px
				}
				h1{
					font-size: 45px
				}
				h3{
					font-size: 18px
				}
			}
			.icon{
				font-size: 45px
			}
			.label{
				font-size: 24px
			}
			.up,.down,.right{
				padding: 2% 2vw;
			}
		}
	}
	.container{
		display: flex;
		flex-wrap: wrap;
		padding: 3rem;
		.title-bar{
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 5%;
			h1{
				font-weight: 700;
			}
		}
		.content{
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
    		background-image: radial-gradient(at center center, rgba(248, 250, 254, 0.6) 60%, rgba(255, 255, 255, 0) 10%);
			transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
			.left{
				display: flex;
				flex-direction: column;
			}
			.up,.down,.right{
				background-color: white;
				border-radius: 15px;
				margin: 1.1rem;
				display: flex;
				flex-direction: column;
				justify-content: start;
				align-items: flex-start;
				>*{ margin: 5px 0; }
			}
		}
		.content::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.5; /* 设置透明度 */
			z-index: -1; /* 确保伪元素在背景下 */
		  }
	}
</style>