<template>
  <div class="home">
    <my-header id="head" @openMenu="changeWrapStatus" @openAbout="openAbout"></my-header>
	<main v-show="isHome">
		<section id="home" class="box1">
			<div class="part1" ref="target1">
				<div :class="targetIsShowed1 ? 'animate__animated animate__slideInDown' : ''">
					<a-icon type="right-circle" />
					{{ cj.box1Text1  }}
					<img src="//s.w.org/images/core/emoji/14.0.0/svg/1f1f3-1f1ec.svg" height="20" width="20">
				</div>
				<div style="margin-bottom:20px;width:100%;"></div>
				<h1 :class="targetIsShowed1 ? 'animate__animated animate__backInUp' : ''">{{ cj.box1Text2 }}</h1>
				<p :class="targetIsShowed1 ? 'animate__animated animate__slideInUp' : ''">{{ cj.box1Text3 }}</p>
				<a-button  :class="targetIsShowed1 ? 'animate__animated animate__slideInUp' : ''" shape="round" @click="getChats">
					<span>{{ cj.box1Text4 }} </span><a-icon type="phone" />
				</a-button>
				<div style="margin-bottom:10px;width:100%;"></div>
				<div :class="targetIsShowed1 ? 'animate__animated animate__slideInUp' : ''" style="font-weight:700;font-size:larger">{{ cj.box1Text5 }}</div>
				<p :class="targetIsShowed1 ? 'animate__animated animate__slideInUp' : ''">{{ cj.box1Text6 }}</p>
				<a-rate :class="targetIsShowed1 ? 'animate__animated animate__slideInUp' : ''" :default-value="4" disabled />
			</div>
			<div ref="target2" class="part2" :class="targetIsShowed2 ? 'animate__animated animate__slideInUp' : ''">
				<section>
					<div>
						<img src="../assets/photo1.png" alt="">
					</div>
				</section>
				<div>
					<div class="icon-box">
						<a-icon type="check-circle" theme="twoTone" />
						<div>  {{ cj.box1Text7 }}</div>
					</div>
					<img src="//27962583.s21i.faimallusr.com/4/ABUIABAEGAAgsrGasAYon_jghwQw5QU43As.png" alt="">
				</div>
			</div>
		</section>
		<section-box id="whyus"></section-box>
		<box1></box1>
		<section class="box2">
			<div class="text">
				<h1>24/7 customer service and Cloud Transaction Quotation system.</h1>
				<span>
					Our real-time quotation platform connects thousands of source buyers and vendors who can provide high prices and bring you more benefits.
				</span>
				<ul>
					<li class="elementor-icon-list-item">
						<span style="margin-right:5px;">
							<a-icon type="check" />
						</span>
						<span class="elementor-icon-list-text">
							Quick Reply.
						</span>
					</li>
					<li class="elementor-icon-list-item">
						<span style="margin-right:5px;">
							<a-icon type="check" />
						</span>
						<span class="elementor-icon-list-text">
							The most competitive rates.
						</span>
					</li>
					<li class="elementor-icon-list-item">
						<span style="margin-right:5px;">
							<a-icon type="check" />
						</span>
						<span class="elementor-icon-list-text">
							Fast loading and payments.
						</span>
					</li>
				</ul>
				<a-button  @click="getChats" shape="round" style="margin: 2.25rem;width: 25%;height: 10%;">
					<span>Get Started</span>
				</a-button>
			</div>
			<div  ref="target3" class="content" :class="targetIsShowed3 ? 'animate__animated animate__slideInLeft' : ''">
				<img src="//sweetygc.com/wp-content/uploads/2023/10/process.png" alt="" />
				<!-- <div style="text-align: right;">
					<div style="margin: -45px 0px 0px 0px;">
						<img src="//sweetygc.com/wp-content/uploads/2023/10/Transaction-process.png" alt="" />
					</div>
				</div> -->
			</div>
		</section>
		<section id="feedback" class="conment">
			<div class="text">
				<h6 class="color-text">{{ cj['comment-small-title'] }}</h6>
				<h1>{{ cj['comment-big-title'] }}</h1>
			</div>
			<div class="swiper">
				<div class="swiper-wrapper">
					<a-carousel :slides-to-show="slideNum" :slides-to-scroll="1">
						<card-item v-for="i in 5" :stars="4" :headSrc="'//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'">
							<span slot="name"> {{ cj['comment-name-' + i] }}</span>
							<span slot="type"> {{ cj['comment-type-' + i] }}</span>
							<span slot="commit">{{ cj['comment-commit-' + i] }}</span>
						</card-item>
					</a-carousel>
				</div>
			</div>
		</section>
		<section id="faqs" class="box3" ref="target4">
			<div class="text" :class="targetIsShowed4 ? 'animate__animated animate__slideInLeft' : ''">
				<h6 class="color-text">{{ cj.qaTitle1 }}</h6>
				<h1>{{ cj.qaBigTitle1 }}</h1>
				<h6 class="color-text">{{ cj.qaTitle2 }}</h6>
				<h3>{{ cj.qaBigTitle2 }}</h3>
				<a-button shape="round" @click="getChats">
					{{ cj.contact }}
				</a-button>
			</div>
			<div class="content" :class="targetIsShowed4 ? 'animate__animated animate__slideInRight' : ''">
				<div style="text-align: left;">
					<a-collapse v-model="activeKey" default-active-key="1" accordion expandIconPosition="right" :class="'open'+activeKey">
						<a-collapse-panel key="1" :header="cj.collapseTitle1">
							<p>{{ cj.collapseContent1 }}</p>
						</a-collapse-panel>
						<a-collapse-panel key="2" :header="cj.collapseTitle2">
							<p>{{ cj.collapseContent2 }}</p>
						</a-collapse-panel>
						<a-collapse-panel key="3" :header="cj.collapseTitle3">
							<p>{{ cj.collapseContent3 }}</p>
						</a-collapse-panel>
						<a-collapse-panel key="4" :header="cj.collapseTitle4">
							<p>{{ cj.collapseContent4 }}</p>
						</a-collapse-panel>
					</a-collapse>
				</div>
			</div>
		</section>
		<section class="box4">
			<div class="text">{{ cj.box4Title }}</div>
			<a-button shape="round" @click="getChats">
				<a-icon type="phone" />{{ cj.box4Btn }}
			</a-button>
		</section>
	</main>
	<about v-show="!isHome"></about>
	<footer-contain></footer-contain>
	<warp id="warp" v-show="showWrap" @closeWarp="changeWrapStatus" @openAbout="openAbout"></warp>
  </div>
</template>

<script>
	import MyHeader from '../components/header.vue'
	import Warp from '../components/warp.vue'
	import FooterContain from '../components/footerContain.vue'
	import SectionBox from '../components/sectionBox.vue'
	import box1 from '../components/box1.vue'
	import cj from '../assets/content.json'
	import CardItem from '../components/card.vue'
	import About from './About.vue'
	import { getChats } from '@/api/api';
	import { useIntersectionObserver } from '@vueuse/core'
	import { ref } from 'vue'

	export default{
		components: { MyHeader, Warp, FooterContain, SectionBox, box1, CardItem, About },
		data() {
			return {
				isHome: true,
				slideNum: 3,
				ori_slide: 3,
				cj,
				activeKey: 1,
				showWrap: false,
				target1: null,
				targetIsShowed1: false,
				target2: null,
				targetIsShowed2: false,
				target3: null,
				targetIsShowed3: false,
				target4: null,
				targetIsShowed4: false,
			}
		},
		methods: {
			changeWrapStatus(status) {
				console.log("status", status)
				this.showWrap = status
			},
			openAbout(status) {
				console.log("openabout", status)
				this.isHome = status
			},
			countSwiperNum() {
				let width = document.documentElement.clientWidth || document.body.clientWidth;
				if(width < 620) {
					this.slideNum = 1
				} else if(width < 1040) {
					this.slideNum = 2
				} else if(width < 1460) {
					this.slideNum = 3
				} else if(width > 1460) {
					this.slideNum = 4
				}
			},
			getChats() {
				getChats();
			}
		},
		mounted() {
			let _this = this
			window.addEventListener('scroll', function() {
				let scrollIndicator = document.querySelector('#head');
				let scrollIndicator1 = document.querySelector('#warp-nav');
				let scrollPosition = window.scrollY; /* 获取当前滚动位置 */
			
				if (scrollPosition >= 100) { /* 根据滚动位置改变样式 */
					scrollIndicator.style.backgroundColor = 'white';
					scrollIndicator.style.padding = '25px'
					scrollIndicator1.style.padding = '25px'
				} else {
					scrollIndicator.style.backgroundColor = 'transparent';
					scrollIndicator.style.padding = '35px';
					scrollIndicator1.style.padding = '35px'
				}
			});
			_this.countSwiperNum()
			window.addEventListener('resize', _this.countSwiperNum);

			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if(entry.isIntersecting) {
						if(entry.target._prevClass == 'part1' && !this.targetIsShowed1) this.targetIsShowed1 = true;
						if(entry.target._prevClass == 'part2' && !this.targetIsShowed2) this.targetIsShowed2 = true
						if(entry.target._prevClass == 'content' && !this.targetIsShowed3) this.targetIsShowed3 = true
						if(entry.target._prevClass == 'box3' && !this.targetIsShowed4) this.targetIsShowed4 = true
					}
				})
			})
			observer.observe(this.$refs.target1)
			observer.observe(this.$refs.target2)
			observer.observe(this.$refs.target3)
			observer.observe(this.$refs.target4)
		}
	}
</script>

<style lang="less" scoped>
	.home{
		transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
		background-image: url(../assets/qq.png);
		background-repeat: no-repeat;
		background-size: cover;
		#head{
			position: fixed;
			width: 100%;
			top: 0;
			background-color: transparent;
			z-index: 5;
		}
		main{
			padding: 0 2.25rem;
			padding-top: 8rem;
		}
	}
	@media screen and (max-width: 527px){
		.box1{
			.part2>*{
				width: 100%;
			}
			.part2>section{
				img{
					width: 72%;
				}
    			margin-left: 7%;
				margin-bottom: 7%;
			}
			.part2>div{
				img{
					width: 80%;
					margin-left: 6%;
				}
				.icon-box{
					margin-bottom: 7%;
				}
				margin-left: 0%;
				align-content: center;
			}
		}
		.box2 text {
			h1{
				font-size: 30px;
			}
			span{
				font-size: 19px;
			}
			li{
				font-size: 16px;
			}
		}
		.box4{
			margin-top: 30%;
		}
	}
	@media screen and (min-width: 527px) and (max-width: 767px){
		.box1{
			.part2>section{
				width: 50%;
				img{
					width: 72%;
				}
    			margin-right: 7%;
			}
			.part2>div{
				width: 50%;
				.icon-box{
					width: 60%;
					margin: 0 18%;
					padding: 0.8rem;
					height: 4rem;
				}
				img{
					width: 80%;
				}
				margin-left: -17%;
				align-content: start;
			}
		}
	}
	@media screen and (max-width: 767px){
		.box1{
			.part1 {
				margin-bottom: 15%;
				h1{
					font-size: 35px;
					font-weight: 900;
				}
			}
			.icon-box{
				width: 60%;
				margin-left: 15%;
				font-size: medium;
				padding: 15px !important;
			}
		}
		.box2{
			flex-direction: column;
			.content>img{
				max-width: 100%;
			}
			.text{
				h1{
					font-size: 35px;
				}
				span{
					font-size: 21px;
				}
				li{
					font-size: 18px;
				}
			}
		}
		.conment{
			background-size: 390px auto;
			.text{
				h1{
					max-width: 50vw;
					font-size: 36px;
				}
				h6{
					font-size: 18px;
				}
			}
		}
	}
	@media screen and (min-width: 767px){
		.box1 {
			.part1{
				width: 50%;
				padding-right: 5%;
				h1{
					font-size: 50px
				}
			}
			.part2{
				margin-left: -10%;
				width: 50%;
				>*{ width: 50% }
			}
			.part2>section{
				img{
					width: 72%;
				}
    			margin-left: 7%;
			}
			.part2>div{
				.icon-box{
					width: 70%;
					font-size: 1.2em;
					margin: 8px 9%;
					padding: 0.8rem;
				}
				img{
					width: 80%;
				}
				margin-left: -8%;
				align-content: start;
			}
		}
		.box2{
			flex-direction: row-reverse;
			.content>img{
				max-width: 80%;
			}
			.text{
				h1{
					font-size: 48px;
				}
				span,li{
					font-size: 18px;
				}
			}
		}
		.conment{
			padding: 10vh;
			background-size: contain;
			.text{
				h1{
					max-width: 30vw;
					font-size: 48px;
				}
				h6{
					font-size: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 1020px){
		.box3{
			flex-direction: column;
			align-items: center;
			>*{
				margin-bottom: 10%;
			}
			.text{
				width: 95%;
				max-width: 370px;
				justify-content: center;
				align-items: center;
				h1{
					font-size: 30px;
					font-weight: 500;
				}
			}
			.content{
				width: 95%;
				font-size: 18px;
				/deep/ .ant-collapse-header {
					font-size: 18px;
				}
				/deep/ .ant-collapse-content {
					font-size: 16px;
				}
			}
		}
		.box4{
			.text{
				font-size: 32px;
				font-weight: 500;
			}
		}
	}
	@media screen and (min-width: 1020px){
		.box3{
			flex-direction: row;
			.text{
				width: 40%;
				text-align: left;
				align-items: start;
				>*{
					margin-bottom: 20px;
				}
				h1{
					font-size: 48px;
					font-weight: 700;
				}
			}
			.content{
				width: 40%;
				font-size: 22px;
				/deep/ .ant-collapse-header {
					font-size: 20px;
				}
				/deep/ .ant-collapse-content {
					font-size: 18px;
				}
			}
		}
		.box4{
			.text{
				font-size: 48px;
				font-weight: 700;
			}
		}
	}
	main>*{
		margin-top: 3.75rem;
		margin-bottom: 2.75rem;
		.color-text{
			color: #6a45ff;
		}
	}
	.box1{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-top: 0;
		.part1{
			display: flex;
			position: relative;
			max-width: 720px;
			flex-wrap: wrap;
			align-content: flex-start;
			text-align: left;
			font-size: 15px;
			h1{
				font-weight: 700;
			}
			button{
				margin: 1rem 2.25rem 1rem 0;
				height: 5vh;
				width: 25%;
				min-width: 130px;
				background-color:#25D366;
				border-color:#25D366;
				color:white;
			}
			button:hover{
				background-color:#FF54B0;
				border-color:#FF54B0;
				transform: scale(1.1);
			}
		}
		.part2{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			>div{
				display: flex;
				flex-direction: column;
				position: relative;
				flex-wrap: wrap;
				justify-content: center;
			}
			.icon-box{
				padding: 5px;
				background-color: white;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: row;
				text-wrap: wrap;
				border-radius: 15px;
			}
			img:hover{
				transform: scale(1.1);
				transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
			}
		}
	}
	.box2{
		display: flex;
		.text{ 
			word-wrap: break-word;
			text-align: left;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 1rem;
			h1{
				font-weight: bold;
			}
			>*{
				margin-bottom: 1rem;
			}
		}
		ul{
			list-style: none;
			h1{
				font-weight: 900;
			}
			li{
				display: flex;
				font-weight: 600;
				align-items: start;
			}
		}
		button{
			background-color: #FF54B0;
			border-color: #FF54B0;
			color: white;
			min-width: 140px;
		}
		.ant-btn:hover{
			background-color:#6A45FF;
			border-color:#6A45FF;
			transform: scale(1.1);
		}
		.content{
			>img{
				height: auto;
				border: none;
				border-style: solid;
				box-sizing: border-box;
				border-width: 12px 30px 12px 30px;
				border-radius: 15px 15px 15px 15px;
				box-shadow: 0px 18px 27px 0px rgba(0, 0, 0, 0.05);
			}
			div img{
				width: 11.25rem;
				height: 8.75rem;
			}
		}
	}
	.conment{
		background-image: url(//sweetygc.com/wp-content/uploads/2023/07/App-Landing-Testimonials-BG.webp);
		background-repeat: no-repeat;
		background-position: 0% 70%;
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 10vh;
		padding-bottom: 10vh;
		.text{
			padding: 0 25px;
			word-wrap: break-word;
			text-align: left;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: start;
			margin-bottom: 40px;
			h1{
				font-family: 'Poppins';
				font-weight: 600;
				line-height: 1.2;
				letter-spacing: -0.027em;
				font-style: normal;
				color: #000000;
			}
		}
		.swiper{
			padding: 25px;
			position: relative;
			overflow: hidden;
		}
	}
	.box3{
		display: flex;
		justify-content: center;
		.text{
			display: flex;
			flex-direction: column;
			margin-bottom: 40px;
			margin-right: 20px;
			padding-right: 40px;
			h6{
				font-size: 16px;
			}
			h3{
				font-size: 18px;
			}
			.color-text{
				font-weight: 600;
			}
			button{
				height: 5vh;
				width: 20vw;
				max-width: 175px;
				min-width: 120px;
				background-color:#25D366;
				border-color:#25D366;
				color:white;
				font-size: 16px;
			}
			button:hover{
				background-color:#FF54B0;
				border-color:#FF54B0;
				transform: scale(1.1);
			}
		}
		.content{
			height: 25rem;
			.ant-collapse{
				background-color: transparent;
				border: none;
				.ant-collapse-item {
					margin: 8px 0 20px 0;
					border-bottom: none;
					/deep/ .ant-collapse-header {
						font-weight: 600;
						border-radius: 6px;
						background-color: white;
						border: 1px solid #d9d9d9 !important;
					}
					/deep/ .ant-collapse-content {
						background-color: rgba(255, 255, 255, 0);
						border: none !important;
					}
				}
				/deep/ .ant-collapse-item-active{
					.ant-collapse-header{
						border: none !important;
						box-shadow: 0px 10px 20px 10px rgba(164, 0, 228, 0.1);
					}
				}
			}
		}
	}
	.box4{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		>*{
			margin-bottom: 20px;
		}
		.text{
			width: 80%;
			word-wrap: break-word;
		}
		button{
			height: 6vh;
			width: 23vw;
			max-width: 260px;
			min-width: 210px;
			background-color: #FF54B0;
			border-color: #FF54B0;
			color: white;
			font-size: 16px;
		}
		.ant-btn:hover{
			background-color:#6A45FF;
			border-color:#6A45FF;
			transform: scale(1.1);
		}
	}
	.ant-carousel{
		/deep/ .slick-slide {
			text-align: center;
			height: 160px;
			line-height: 160px;
			background: #364d79;
			overflow: hidden;
		}
	}
	  
	.ant-carousel{
		/deep/ .slick-slide h3 {
			color: #fff;
		}
		/deep/.slick-slide{
			height: 50vh;
			line-height: normal;
			background: none;
			padding: 0 0.3%;
			>div{
				height: 100%;
			}
		}
	}
</style>

