<template>
	<div class="foot">
		<section>
			<div class="introduce">
				<img src="../assets/icon.png" alt="" height="100" width="120">
				<p>The best gift cards trading platform that help you easily sell your gift cards at the best rates online.</p>
			</div>
			<div>
				<div class="title">About us</div>
				<div>COMPANY</div>
				<div>PRIVACY POLICY</div>
			</div>
			<div>
				<div class="title">Find us</div>
				<div @click="getChats" style="cursor: pointer;">
					<a-icon type="phone" /><span style="color:#00DDC1"> WhatsApp</span>
				</div>
			</div>
			<div class="contact">
				<div class="title">We’re always happy to help.</div>
				<div>
					<a-icon type="phone" />
					+8613275994499
				</div>
				<div>
					<div>
						<a-icon type="environment" />
						No. 963, JinHui Road, Licheng District, HangZhou City, ZheJiang Province
					</div>
				</div>
			</div>
			<div></div>
		</section>
		<div>© 2024 · Lengex Gift Cards Exchange</div>
	</div>
</template>

<script>
	import { getChats } from '@/api/api';
	import CardItem from './card.vue'
	export default{
		name: 'FooterContain',
		components: {
			CardItem
		},
		methods: {
			getChats() {
				getChats();
			}
		}
	}
</script>

<style scoped>
@media screen and (max-width:920px){
	section{
		flex-wrap: wrap;
		margin: 0 1%;
	}
}
@media screen and (min-width: 920px){
	section{
		justify-content: center;
		.introduce, .contact{
			width: 28%;
		}
	}
}
	.foot{
		padding: 30px;
		background-color: #6a45ff;
		background-image: url(https://Lengex.com/wp-content/uploads/2023/07/App-Landing-Footer-BG-scaled-1.webp);
		background-position: bottom center;
		background-repeat: no-repeat;
		background-size: cover;
		color: white;
		font-size: 16px;
		>div{
			color: white;
		}
	}
	section{
		width: 90%;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		position: relative;
		margin-bottom: 1.875rem;
		>*{
			display: flex;
			flex-direction: column;
			align-items: start;
			text-align: left;
			padding: 15px;
			>*{
				margin-bottom: 15px;
			}
		}
		.title{
			font-size: large;
			font-weight: 600;
		}
		.introduce{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	@media screen and (min-width: 650px){
		.introduce{
			max-width: 30vw;
		}
		.contact{
			max-width: 40vw;
		}
	}
	@media screen and (max-width: 650px){
		section>*{
			width: 100%;
		}
	}
</style>