import axios from 'axios';

export function getChats() {
    axios.get("https://giftcard.lengex.com/gateway/app/getChats")
        .then(res => {
            let curNum = localStorage.getItem('url_Num') || 0;
            if(curNum >= res.data.data.length) curNum = 0;
            window.open('https://wa.me/' + res.data.data[curNum]['chatTel'] )
            localStorage.setItem('url_Num', (curNum + 1) % res.data.data.length);
        })
}